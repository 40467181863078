require('../css/article.css');
require('slick-carousel/slick/slick.css')
require('slick-carousel/slick/slick-theme.css')
require('infinite-scroll/dist/infinite-scroll.pkgd.min');
require('slick-carousel/slick/slick')

$(document).ready(function() {

});

window.bindInfiniteScroll = function(isAutoLoad) {
    var options = {
        path: params.url + '?page={{#}}',
        append: '.article',
        history: false,
        status: '.page-load-status'
    };
    if (isAutoLoad == false) {
        options.button = '.btn-view-more';
        options.scrollThreshold = false;
    }

    var $container = $('.card-infinite .card-body').infiniteScroll(options);
    $container.on('history.infiniteScroll', function () {
        dataLayer.push({
            'event': 'virtualPageView',
            'virtualUrl': location.pathname
        });
    });
    $container.on('load.infiniteScroll', function (event,response, path) {
        var text = $(response).text();
        if (!$.trim(text)) {
            $container.infiniteScroll('destroy');
            $('.page-load-status .infinite-scroll-request').hide();
            $('.page-load-status .infinite-scroll-last').show();
        }
    });
}

window.bindGridSlick = function() {
    $('.grid').slick({
        dots: true,
        arrows: false,
        infinite: true,
        slidesToShow: 8,
        slidesToScroll: 4,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 6,
                    slidesToScroll: 3
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3
                }
            }
        ]
    });
}